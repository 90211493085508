$(document).ready(function () {
    setupAjaxField();
});

window.setupAjaxField = function() {
    console.log('Setup Ajax Field');
    //bind to our checkin box on checkin screen
    bindAjax('.checkin-box','.registration');

    bindAjax('.ajax_field_form', '.ajax-field');
    ajaxFieldInit();

    //rebind after any ajax call is complete. so if we return a ajax_field inside of another partial it runs correct
    $(document).ajaxStop( function(){
        bindAjax('.ajax_field_form', '.ajax-field');
        bindAjax('.checkin-box','.registration');
        ajaxFieldInit();
    });

    function bindAjax(bindTo, htmlReplace) {
        $(bindTo).unbind('ajax:beforeSend');
        $(bindTo).unbind('ajax:error');
        $(bindTo).unbind('ajax:success');

        $(bindTo).bind('ajax:beforeSend', function () {
            $(this).html('<i class="fa fa-spinner fa-pulse loading"></i>');
        });

        $(bindTo).bind('ajax:error', function () {
            $(this).html('<i class="fa fa-exclamation-triangle error"></i>');
        });

        $(bindTo).bind('ajax:success', function (evt, data, status, xhr) {
            $(this).closest(htmlReplace).replaceWith(data);
        });
    }

    function ajaxFieldInit() {
        $('.add-resource').click(function () {
            $(this).hide();
            $(this).parent().children('.value-box').show();
            $(this).parent().children('.value-box').focus();
        });

        $('.value-box').each(updateValue);
        $('.value-box').focusout(updateValue);
        $('.value-box').focusout(updateServer);
    }


    function updateValue() {
        if ($(this).val().length === 0) {
            $(this).hide();
            $(this).parent().children('.fa').show();
        } else {
            $(this).show();
            $(this).parent().children('.fa').hide();
        }
    }

    function updateServer() {
        var form = $(this).closest('form')[0];
        Rails.fire(form, 'submit');
    }

    $('.head_field_text_input').change(function () {
        var newValue = $(this).val().toUpperCase();
        $(this).val(newValue);

        var updateField = $(this).data('fieldName');

        var data2post = {
            checkin: true
            //judging_placing: newValue
        };

        var head = {}
        head[updateField] = newValue;

        data2post.head = head;
        if (updateField === 'animal_dob'){
            if (isDateValid(newValue) === false){
                alert("Please enter a valid birthdate after reloading the page.");
                return 0;
            }
        }
        $.post($(this).data('ajaxUrl'), data2post)
            .fail(function () {
                alert('Could not update entry.  Refresh the page.');
            });
    });

    $('.registration_question_field').change(function() {
        var data2post = {
            head_id: $(this).data('head-id'),
            registration_question_id: $(this).data('registration-question-id'),
            registration_form_id: $(this).data('registration-form-id'),
            value: $(this).val()
        }

        $.post($(this).data('ajaxUrl'), data2post)
            .fail(function () {
                alert('Could not update entry.  Refresh the page.');
            });
    });
}
