//
// Registration Department Selection Page - edit_department
//

$(document).ready(function () {

    // Department / Department Selection onClick Handler
    $('#department_edit_div').on("click", ".department_row", null, function (event, manual) {


        if (typeof manual === "undefined") {
            // Need to clear future clicks
            $('#registration_department_id').val('');
            $('#registration_division_id').val('');
            $('#registration_division_category_id').val('');
            $('#registration_registration_variant_id').val('');
        }



        //check to see if we set our current max entries OR is Closed, if we did and we have hit limit dont continue
        if (!$(event.currentTarget).data('sfEntriesOpen') && !($('#department_edit_div').data('employeeSignedIn') == true)) {
            return;
        }

        $('.selected_department_row').removeClass('selected_department_row');

        $.ajax({
            url: "/registrations/select_division/" + $(event.currentTarget).data('departmentId'),
            data: 'registration_type=' + $('.department-list').data('registrationType')
        }).done(function (newhtml) {
            $('#division_edit_div').html(newhtml);
            $('#category_edit_div').html('');
            $('#registration_variant_edit_div').html('');

            var registration_division_id = $('#registration_division_id').val();
            if (registration_division_id.length > 0) {
                var x = $('.division_row[data-division-id=' + registration_division_id + ']');

                if (x.length) {
                    x.trigger("click", "onpageload");
                }
            } else if ($('.division_row').length == 1) {
                // If there is only one, select it for the user
                $('.division_row').trigger("click");
            }
        });

        $(event.currentTarget).addClass('selected_department_row');

        //if an admin is editing this registration after the order is paid we need to not let them edit department
        // if( $('div[data-sf-registration-status]').data('sfRegistrationStatus'))
        // {
            // $('.department-list').html(" Entry has been Paid, Department locked")
            //$('.department-list').hide();
        // }
    });

    // Department / Division Selection onClick Handler
    $('#division_edit_div').on("click", ".division_row", null, function (event, manual) {

        if (typeof manual === "undefined") {
            // Need to clear future clicks
            $('#registration_division_id').val('');
            $('#registration_division_category_id').val('');
            $('#registration_registration_variant_id').val('');
        }

        //check to see if we set our current max entries OR is Closed, if we did and we have hit limit dont continue
        if (($(event.currentTarget).data('sfEntriesAvailable') == 0 || !$(event.currentTarget).data('sfEntriesOpen')) && !($('#department_edit_div').data('employeeSignedIn') == true)) {
            return;
        }


        $('.selected_division_row').removeClass('selected_division_row');

        $.ajax({
            url: "/registrations/select_category/" + $(event.currentTarget).data('divisionId')
        }).done(function (newhtml) {
            $('#category_edit_div').html(newhtml);
            $('#registration_variant_edit_div').html('');
            $('.sf-submit-button').off();
            $('.sf-submit-button').addClass('pure-button-disabled');

            var registration_division_category_id = $('#registration_division_category_id').val();
            if (registration_division_category_id.length > 0) {
                var x = $('.category_row[data-category-id=' + registration_division_category_id + ']');

                if (x.length) {
                    x.trigger("click", "onpageload");
                }
            } else if ($('.category_row').length == 1) {
                // If there is only one, select it for the user
                $('.category_row').trigger("click");
            }
        });

        $(event.currentTarget).addClass('selected_division_row');
    });

    // Department / Category Selection onClick Handler
    $('#category_edit_div').on("click", ".category_row", null, function (event, manual) {

        if (typeof manual === "undefined") {
            // Need to clear future clicks
            $('#registration_division_category_id').val('');
            $('#registration_registration_variant_id').val('');
        }

        //check to see if we set our current max entries OR is Closed, if we did and we have hit limit dont continue
        if (($(event.currentTarget).data('sfEntriesAvailable') == 0 || !$(event.currentTarget).data('sfEntriesOpen')) && !($('#department_edit_div').data('employeeSignedIn') == true)) {
            return;
        }

        $('.selected_category_row').removeClass('selected_category_row');

        var url = "/registrations/select_registration_variant/" + $(event.currentTarget).data('categoryId');

        $.ajax({
          url: url
        }).done(function (newhtml) {
          $('#registration_variant_edit_div').html(newhtml);
          $('.sf-submit-button').off();
          $('.sf-submit-button').addClass('pure-button-disabled');

          var registration_registration_variant_id = $('#registration_registration_variant_id').val();
          if (registration_registration_variant_id.length > 0) {
              var x = $('.registration_variant_row[data-sf-class-id=' + registration_registration_variant_id + ']');

              if (x.length) {
                  x.trigger("click", "onpageload");
              }
          } else if ($('.registration_variant_row').length == 1) {
              // If there is only one, select it for the user
              $('.registration_variant_row').trigger("click");
          }
        });

        $('#registration_division_category_id').val($(event.currentTarget).data('categoryId'));

        if (typeof manual === "undefined") {
            $('#registration_registration_variant_id').val('');
        }

        $(event.currentTarget).addClass('selected_category_row');
    });

    // Department / Class Selection onClick Handler
    $('#registration_variant_edit_div').on("click", ".registration_variant_row", null, function (event, manual) {


        if (typeof manual === "undefined") {
            // Need to clear future clicks
            $('#registration_registration_variant_id').val('');
        }

        //check to see if we set our current max entries OR is Closed, if we did and we have hit limit dont continue
        if (($(event.currentTarget).data('sfEntriesAvailable') == 0 || !$(event.currentTarget).data('sfEntriesOpen')) && !($('#department_edit_div').data('employeeSignedIn') == true)) {
            return;
        }

        $('.selected_registration_variant_row').removeClass('selected_registration_variant_row');

        $('#registration_registration_variant_id').val($(event.currentTarget).data('sfClassId'));

        $(event.currentTarget).addClass('selected_registration_variant_row');

        $('.sf-submit-button').removeClass('pure-button-disabled');

        var rowi = $(event.currentTarget).data('sfIndianaClass') ? 'true' : 'false';
        var regi = $('#indiana_classes_allowed').val();

        // on selecting the class enable submit button if its not a indiana only class
        if (regi == 'false' && rowi == 'true') {
            $('.sf-submit-button').addClass('pure-button-disabled');
            alert('This Class is Available to Indiana Residents Only');
            $('.sf-submit-button').off("click");
        } else {
            $('.sf-submit-button').removeClass('pure-button-disabled');
            $('.sf-submit-button').click(function (event) {
                Rails.fire($('#registration_form')[0], 'submit');
            });
        }
    });

    // Populate Department if one previously selected
    if ($('#registration_department_id').length && $('#registration_department_id').val().length > 0) {
        var x = $('.department_row[data-department-id=' + $('#registration_department_id').val() + ']');

        if (x.length) {
            x.trigger("click", "onpageload");
        }
    }

});
