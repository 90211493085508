var nextNewSplitId = -1000;

$(document).ready(function() {
  $(".show-class-table").sortable({
    items: "tr:not(tr:first-child)",
    cursor: "pointer",
    axis: "y",
    dropOnEmpty: false,
    start: function(e, ui) {
      ui.item.addClass("selected");
    },
    stop: function(e, ui) {
      reParentRegistrations();
      ui.item.removeClass("selected");
    },
  });

  $(".show-class-table").on("click", ".rv-split-button", function(event) {
    event.preventDefault();
    rvtr = $(event.target)
      .parents(".show-variant-tr")
      .first();

    splitAmount = rvtr.data("registrationEntryCount");
    if (isNaN(splitAmount) || splitAmount <= 0) {
      splitAmount = 2;
    } else {
      splitAmount = Math.floor(splitAmount / 2);
    }

    splitRegistrationVariantTR(rvtr, splitAmount);
  });

  $(".show-class-table").on("click", ".rv-un-split-button", function(event) {
    event.preventDefault();
    rvtr = $(event.target)
      .parents(".show-variant-tr")
      .first();
    unSplitRegistrationVariantTR(rvtr);
  });

  $("#save_button").on("click", function(event) {
    doSave(event);
  });

  reParentRegistrations();
});

function splitRegistrationVariantTR(rvtr, how_many) {
  var regSplitPoint = null;
  rvtr.nextUntil(".show-variant-tr").each(function(index) {
    regSplitPoint = $(this);
    if (how_many-- != 0) {
      return true;
    }
    return false;
  });

  if (regSplitPoint == null) {
    return;
  }

  new_rvtr = rvtr.clone(false);

  // Make name sane
  var newName = $(rvtr)
    .find(".sv-name")
    .first()
    .text();

  if (/ - \w+$/.test(newName)) {
    var endmatch = newName.match(/ - (\w+)$/);
    var newEndChar = endmatch[1];

    newName = newName.substr(0, newName.length - newEndChar.length);

    newEndChar = getNextKey(newEndChar);

    new_rvtr
      .find(".sv-name")
      .first()
      .text(newName + newEndChar);
  } else {
    new_rvtr
      .find(".sv-name")
      .first()
      .text(newName + " - 001");
  }

  //new_rvtr.find('.sv-name').first().text(newEndChar + 'XXX' + newName);

  new_rvtr.data("showVariantId", nextNewSplitId--);
  //new_rvtr.addClass('show-variant-tr');

  new_rvtr.insertBefore(regSplitPoint);

  reParentRegistrations();
  renumberTemps();
  setupSplitRow();
}

function getNextKey(key) {
  if (/^z+$/.test(key)) {
    // If all z's, replace all with a's
    key = key.replace(/z/g, "a") + "a";
  } else {
    // (take till last char) append with (increment last char)
    key =
      key.slice(0, -1) + String.fromCharCode(key.slice(-1).charCodeAt() + 1);
  }
  return key;
}

function unSplitRegistrationVariantTR(rvtr) {
  rvtr.remove();
  reParentRegistrations();
  renumberTemps();
  setupSplitRow();
}

// This function resets the parent keys
function reParentRegistrations() {
  var sv_id = -1;
  var sv_parent = null;
  var previous_count = 0;

  $(".show-class-table tr").each(function(index) {
    if ($(this).hasClass("show-variant-tr")) {
      if (sv_parent != null) {
        $(sv_parent)
          .find(".entry-count")
          .text(previous_count + " Entries");
        $(sv_parent).data("registrationEntryCount", previous_count);

        if (previous_count >= 2) {
          $(sv_parent)
            .find("button.rv-split-button")
            .prop("disabled", false);
        } else {
          $(sv_parent)
            .find("button.rv-split-button")
            .prop("disabled", true);
        }

        previous_count = 0;
      }

      sv_parent = $(this);
      sv_id = $(this).data("showVariantId");

      if (index == 0) {
        $(sv_parent)
          .find("button.rv-un-split-button")
          .prop("disabled", true);
      } else {
        $(sv_parent)
          .find("button.rv-un-split-button")
          .prop("disabled", false);
      }
    }

    if ($(this).hasClass("registration-tr")) {
      $(this).data("svParentId", sv_id);
      previous_count++;
    }
  });

  if (sv_parent != null) {
    $(sv_parent)
      .find(".entry-count")
      .text(previous_count + " Entries");
    $(sv_parent).data("registrationEntryCount", previous_count);

    if (previous_count >= 2) {
      $(sv_parent)
        .find("button.rv-split-button")
        .prop("disabled", false);
    } else {
      $(sv_parent)
        .find("button.rv-split-button")
        .prop("disabled", true);
    }
  }
}

function dragDrop(drugRegistration, dropTarget) {
  var drugIndex = drugRegistration.data("registrationIndex");

  var lastDrop = dropTarget;
  // Find the insertion point...
  dropTarget.nextUntil(".show-variant-tr").each(function(index) {
    if ($(this).data("registration-index") > drugIndex) {
      return false;
    }

    lastDrop = $(this);
  });

  drugRegistration.insertAfter(lastDrop);

  reParentRegistrations();
}

function doSave(event) {
  reParentRegistrations();

  bigJ = {};
  bigJ.show_variants = {};
  bigJ.registrations = {};

  $(".show-class-table tr").each(function(index) {
    if ($(this).hasClass("show-variant-tr")) {
      data_hash = {};
      data_hash["id"] = $(this).data("showVariantId");
      data_hash["name"] = $(this)
        .find(".sv-name")
        .first()
        .text();
      data_hash["report_order"] = $(this).data("reportOrder");
      data_hash["business_eid"] = $(this)
        .find(".sv-class-number")
        .first()
        .text();
      bigJ.show_variants[data_hash["id"]] = data_hash;
    }

    if ($(this).hasClass("registration-tr")) {
      data_hash = {};
      data_hash["id"] = $(this).data("registrationId");
      data_hash["sv-parent"] = $(this).data("svParentId");
      //data_hash['name'] = $(this).children('.sv-name').first().text();
      bigJ.registrations[data_hash["id"]] = data_hash;
    }
  });

  theForm = $("form.edit_registration_variant").first();

  $("#new_world_order").val(JSON.stringify(bigJ));

  theForm.submit();
}

function renumberTemps() {
  $(".sv-label-div").each(function(index) {
    $this = $(this);

    $this.attr("data-temp", index);
  });
}

function setupSplitRow() {
  $(".sv-label-div").dblclick(function() {
    var original_div = $(this);

    $("#sv-edit-dialog-temp-index").val(original_div.data("temp"));

    var sv_class_number = $(original_div).children(".sv-class-number");
    if ($(sv_class_number).length > 0) {
      $("#sv-edit-dialog-class-number").val($(sv_class_number).text());
    }

    var sv_class_name = $(original_div).children(".sv-name");

    $("#sv-edit-dialog-name").val($(sv_class_name).text());

    $("#sv-edit-dialog").modal("show");

    $("#sv-edit-dialog-class-number").change(function() {
      $this = $(this);
      var temp_index = $("#sv-edit-dialog-temp-index").val();
      var original_div = $("[data-temp='" + temp_index + "']");
      var sv_class_number = $(original_div).children(".sv-class-number");
      if ($this.val().length > 0) {
        $(sv_class_number).text($("#sv-edit-dialog-class-number").val());
      }
    });

    $("#sv-edit-dialog-name").change(function() {
      $this = $(this);
      var temp_index = $("#sv-edit-dialog-temp-index").val();
      var original_div = $("[data-temp=" + temp_index + "]");
      var sv_class_name = $(original_div).children(".sv-name");
      if ($this.val().length > 0) {
        $(sv_class_name).text($("#sv-edit-dialog-name").val());
      }
    });
  });
}

$(document).ready(function() {
  setupSplitRow();
});
