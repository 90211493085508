$(function () {
  $(document).on("change", "#department_box", function () {
    $('#division_box').empty();
    $('#division_category_box').empty();
    $('#class_box').empty();

    handleDepartmentBoxChange($('#department_box :selected').length);
  });

  $(document).on("change", "#division_box", function () {
    handleDivisionBoxChange($('#division_box :selected').length);
  });

  $(document).on("change", "#division_category_box", function () {
    handleDivisionCategoryBoxChange();
  });
});

function handleDepartmentBoxChange (numberOfDepartments) {
  if (numberOfDepartments === 1) {
    $('#division_box').empty();
    $('#division_box').removeAttr('disabled');
    $('#division_box')[0].selectize.enable();
    $('#division_category_box').attr('disabled', 'disabled');
    $('#class_box').attr('disabled', 'disabled');
    department_id = $('#department_box').val();

    $.ajax('/utilities/division_select/' + department_id, {
      type: 'GET',
      dataType: 'json',
      success: function (result) {
        $('#division_box').selectize()[0].selectize.destroy();
        $('#division_box').html(result.divisions);
        $('#division_box').selectize({
          plugins: ['remove_button'],
          dropdownParent: 'body',
          closeAfterSelect: true
        });
      },
      error: function (xhr, _, thrownError) {
        console.log(xhr.status);
        console.log(thrownError);
        console.log(xhr.responseText);
      }
    })
  } else if (numberOfDepartments > 1) {
    $('#division_box').removeAttr('disabled');
    $('#division_box').selectize()[0].selectize.destroy();
    $('#division_category_box').attr('disabled', 'disabled');
    $('#class_box').attr('disabled', 'disabled');
    department_ids = $('#department_box').val();

    $.ajax('/utilities/multi_division_select', {
      type: 'GET',
      dataType: 'json',
      data: {
        department_ids: department_ids
      },
      success: function (result) {
        $('#division_box').html(result.division_distinct);
        $('#division_box').selectize({
          plugins: ['remove_button'],
          dropdownParent: 'body',
          closeAfterSelect: true
        });
      },
      error: function (xhr, _, thrownError) {
        console.log(xhr.status);
        console.log(thrownError);
        console.log(xhr.responseText);
      }
    })
  } else if (numberOfDepartments === 0) {
    $('#division_box').attr('disabled', 'disabled');
    $('#division_box')[0].selectize.disable();
    $('#division_box')[0].selectize.clearOptions();
    $('#division_category_box').attr('disabled', 'disabled');
    $('#class_box').attr('disabled', 'disabled');
  } else {
    console.log('error');
  }
};

function handleDivisionBoxChange (numberOfDivisions) {
  var division_id, division_ids;

  if (numberOfDivisions === 1) {
    $('#division_category_box').empty();
    $('#class_box').empty();
    $('#division_category_box').removeAttr('disabled');
    $('#class_box').attr('disabled', 'disabled');
    division_id = $('#division_box').val();

    $.ajax('/utilities/division_category_select/' + division_id, {
      type: 'GET',
      dataType: 'json',
      success: function (result) {
        $('#division_category_box').html(result.division_categories);
      },
      error: function (xhr, _, thrownError) {
        console.log(xhr.status);
        console.log(thrownError);
        console.log(xhr.responseText);
      }
    });
  } else if (numberOfDivisions > 1) {
    $('#class_box').attr('disabled', 'disabled');
    $('#division_category_box').removeAttr('disabled');
    division_ids = $('#division_box').val();

    $.ajax('/utilities/multi_division_category_select', {
      type: 'GET',
      dataType: 'json',
      data: {
        divisions_ids: division_ids
      },
      success: function (result) {
        $('#division_category_box').html(result.division_categories_distinct);
      },
      error: function (xhr, _, thrownError) {
        console.log(xhr.status);
        console.log(thrownError);
        console.log(xhr.responseText);
      }
    });
  } else if (numberOfDivisions === 0) {
    $('#division_category_box').empty();
    $('#class_box').empty();
    $('#division_category_box').attr('disabled', 'disabled');
    $('#class_box').attr('disabled', 'disabled');
  } else {
    console.log('Error!');
  }
};

function handleDivisionCategoryBoxChange () {
  var division_category_id;
  $('#class_box').empty();

  if ($('#division_category_box').val() === '' || $('#division_box :selected').length > 1) {
    $('#class_box').attr('disabled', 'disabled');
  } else {
    $('#class_box').removeAttr('disabled');
  }

  if ($('#division_category_box').val() !== '' && $('#division_box :selected').length === 1) {
    division_category_id = $('#division_category_box').val();

    $.ajax('/utilities/registration_variant_select/' + division_category_id, {
      type: 'GET',
      dataType: 'json',
      success: function (result) {
        $('#class_box').html(result.registration_variants);
      },
      error: function (xhr, _, thrownError) {
        console.log(xhr.status);
        console.log(thrownError);
        console.log(xhr.responseText);
      }
    });
  }
}
