// This file is used to handle code associated with "Add Outside Award"
// on the judging entry page.

import { assignmentWarning, updateDisplayedAwardsForRegistrationId, reloadExhibitorAwards } from './awarding';

// do below rather than $(document).ready(function () {});
$(function () {
  $(document).on("click", ".ec-outside-award-search-result", function () {
    const type = $(this).data("type");

    if (type === "Exhibitor") {
      // All done, so we can assign the award and move on.
      const specialAwardId = getModalGlobal("special-award-id");
      const exhibitorId = $(this).data("id");

      awardExhibitor(specialAwardId, exhibitorId);
    } else if (type === "Customer") {
      setModalPage("select_exhibitor", {
        customer_id: $(this).data("id"),
        award_id: getModalGlobal("special-award-id")
      });
      clearModalFooter();

      // We will need to keep track of the customer that was selected,
      // as if the user creates a new exhibitor we need to know which
      // customer to attach it to.
      setModalGlobal("customer-id", $(this).data("id"));
      $("#newExhibitorModal").data("customer-id", $(this).data("id"));
    } else {
      alert("Something has gone wrong.");
    }
  });

  $(document).on("click", ".ec-outside-award-select", function () {
    const input = $(this);

    assignmentWarning(input, registrationId => {
      setModalPage("search");

      // We need to keep track of which award was checked so that
      // we can assign it once we have the other end of the association.
      // Storing it on the modal is safe, as it is not going to be removed
      // and recreated.
      setModalGlobal("special-award-id", input.val());

      // We also need to keep track of the current holder so that we
      // can refresh their data display.
      setModalGlobal("holder-id", registrationId);
    });
  });

  $(document).on("click", ".ec-select-exhibitor", function () {
    const specialAwardId = getModalGlobal("special-award-id");
    const exhibitorId = $(this).data("id");

    awardExhibitor(specialAwardId, exhibitorId);
  });

  $(document).on("submit", ".outside_awards_new_exhibitor", function (e) {
    e.preventDefault();

    const existingCustomer = getModalGlobal("customer-id");
    const specialAwardId = getModalGlobal("special-award-id");

    const data = $(this).serializeArray();

    if (!existingCustomer) {
      // To make a customer first, we need to put the object into a 'customer' field
      // of an object.
      const customerData = data.reduce((o, kv) => ({...o, [kv.name.replace("exhibitor", "customer")]: kv.value}), {});
      customerData["customer[email]"] = getModalGlobal("customer-new-email");

      $.ajax({
        method: "POST",
        url: "/employee_create_customer",
        data: customerData,
        dataType: "json",
        success: function (res) {
          const customerId = res.customer_id;

          for (let i = 0; i < data.length; i++) {
            if (data[i].name === "customer_id") {
              data[i].value = customerId;
              break;
            }
          }

          createAndAwardExhibitor(specialAwardId, data);
        }
      });
    } else {
      createAndAwardExhibitor(specialAwardId, data);
    }
  });

  $(document).on("click", ".ec-change-exhibitor-award", function () {
    resetModalGlobals();

    // By setting this global, we change the behavior of awarding an exhibitor.
    setModalGlobal("assignee-id", $(this).data("exhibitor-id"));
    setModalGlobal("special-award-id", $(this).data("award-id"));

    setModalPage("search");
    setModalTitle($(this).data("award-name"));
    setModalFooter("unassign", {
      award_id: $(this).data("award-id"),
      assignee_id: $(this).data("exhibitor-id")
    });
  });

  $(document).on("click", ".ec-delete-exhibitor-award", function () {
    $.ajax({
      method: "PATCH",
      url: `/special_awards/${$(this).data("award-id")}/set_holder`,
      data: {
        exhibitor_ids: [$(this).data("exhibitor-id")],
        checked: false
      },
      success: function () {
        reloadExhibitorAwards();
        closeModals();
      }
    });
  });

  $(document).on("click", ".ec-outside-award-email", function () {
    // Access the value through parent/child relationships to allow for multiple
    // search boxes on the same page.
    setModalGlobal("customer-new-email", $(this).parent().parent().siblings(".omni-search-box").val());
  });

  $(document).on("click", ".ec-assign-award", function () {
    resetModalGlobals();

    setModalGlobal("special-award-id", $(this).data("award-id"));
    // Do not need to set holder-id as in the other flow because
    // if an exhibitor award is only ever assigned to exhibitors,
    // we will not show the "assign" option unless the award is
    // unassigned.

    setModalPage("search");
    setModalTitle($(this).data("award-name"));
  });

  $(".add-outside-award-dialog").on("hide.bs.modal", function () {
    clearModalFooter();
  });

  function setModalPage(page, data) {
    $.ajax({
      url: `/reports/outside_awards_pages/${page}`,
      data,
      success: function (res) {
        $(".ec-add-outside-award-body").html(res);
        $(".add-outside-award-dialog").modal("show");
      }
    });
  }

  function setModalFooter(page, data) {
    $.ajax({
      url: `/reports/outside_awards_footer/${page}`,
      data,
      success: function (res) {
        $(".ec-add-outside-award-footer").removeAttr("style");
        $(".ec-add-outside-award-footer").html(res);
      }
    });
  }

  function clearModalFooter() {
    $(".ec-add-outside-award-footer").attr("style", "display: none");
    $(".ec-add-outside-award-footer").html("");
  }

  function setModalTitle(title) {
    $(".ec-outside-award-modal-title").text(`Assign ${title}`);
  }

  function awardExhibitor(specialAwardId, exhibitorId) {
    const assignee = getModalGlobal("assignee-id");

    if (assignee) {
      $.ajax({
        method: "PATCH",
        url: `/special_awards/${specialAwardId}/set_holder`,
        data: {
          exhibitor_ids: [assignee],
          checked: false
        },
        success: function () {
          sendAwardRequest(specialAwardId, exhibitorId);
        }
      });
    } else {
      sendAwardRequest(specialAwardId, exhibitorId);
    }
  }

  function sendAwardRequest(specialAwardId, exhibitorId) {
    $.ajax({
      method: "PATCH",
      url: `/special_awards/${specialAwardId}/set_holder`,
      data: {
        exhibitor_ids: [exhibitorId],
        checked: true
      },
      success: function () {
        closeModals();

        // longer-term, it would be better to just load this display from the API again
        // this is re-implementing rules that would be better served to be in one place
        const holderId = getModalGlobal("holder-id");

        $(`.judging_select_input[data-registration-id=${holderId}][value=${specialAwardId}]`).prop("checked", false);
        updateDisplayedAwardsForRegistrationId(holderId);

        reloadExhibitorAwards();

        alert("Award has been assigned successfully.");
      }
    });
  }

  function createAndAwardExhibitor(specialAwardId, payload) {
    $.ajax({
      method: "POST",
      url: "/exhibitors",
      data: payload,
      dataType: "json",
      success: function (res) {
        const exhibitorId = res.exhibitor_id;

        awardExhibitor(specialAwardId, exhibitorId);
      }
    });
  }

  function getModalGlobal(name) {
    return $(".add-outside-award-dialog").data(name);
  }

  function setModalGlobal(name, value) {
    $(".add-outside-award-dialog").data(name, value);
  }

  function resetModalGlobals() {
    $(".add-outside-award-dialog").removeData();
  }

  function closeModals() {
    $("#newExhibitorModal").modal("hide");
    $(".add-outside-award-dialog").modal("hide");
  }
});
