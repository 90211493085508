$(document).ready(function () {
    $('.finalize_button').bind('ajax:success', function (evt, data, status, xhr) {
        $(".watermark_container").removeClass("watermark");
    });

    bindAjax('.edit_registration', '.judging_panel');
    bindAjax('.award-listed', '.judging_panel');

    $(document).ajaxStop( function(){
        bindAjax('.edit_registration', '.judging_panel');
        bindAjax('.award-listed', '.judging_panel');
    });


    function bindAjax(bindTo, htmlReplace) {
        $(bindTo).unbind('ajax:beforeSend');
        $(bindTo).unbind('ajax:error');
        $(bindTo).unbind('ajax:success');


        $(bindTo).bind('ajax:beforeSend', function () {
            $(this).html('<i class="fa fa-spinner fa-pulse loading"></i>');
        });

        $(bindTo).bind('ajax:error', function () {
            $(this).html('<i class="fa fa-exclamation-triangle error"></i>');
        });

        $(bindTo).bind('ajax:success', function (evt, data, status, xhr) {
            $(this).closest(htmlReplace).replaceWith(data);
        });
    }

});

