var dialogEvent;
$(document).ready(function () {
  function checkRequiredFields() {
    var blankRequired = 0;
    $.each($(".required"), function () {
      if ($(this).val().trim() == '') {
        if ($(this).hasClass('required-field-error') == false) {
          $(this).addClass("required-field-error");
        }
        blankRequired++;
      }
      else {
        $(this).removeClass('required-field-error');
      }

    });

    $.each($(".collapsible"), function () {
      if ($(this).children(".collapsible-body").find(".required-field-error").length) {
        if ($(this).children(".collapsible-head").first().hasClass("required-field-error") == false) {
          $(this).children(".collapsible-head").first().addClass("required-field-error");
        }
      } else {
        $(this).children(".collapsible-head").first().removeClass("required-field-error");
      }
    });
    // alert("Blank required: " + blankRequired);
    return blankRequired;
  }

  function noRegistrationsOnPage() {
    var registrationOnPage = $("[id$=_registration]");
    if ( registrationOnPage.length == 0 ) {
      // alert("registrationOnPage: " + registrationOnPage.length);
      return true;
    }
    else {
      // alert("registrationOnPage: " + registrationOnPage.length);
      return false;
    }
  }

  function checkEditableHeads() {
    var unEditableHeads = $("[data-head-editable='false']");
    var disabledFields = unEditableHeads.find('.pure-input-1');

    unEditableHeads.children(".uneditable_message").show();

    $.each(disabledFields, function () {
      $(this).prop('disabled', true);
    });
  }

  $("#next_button").click(function () {
    if ($(this).hasClass('pure-button-disabled') == false) {
      window.location.href = $(this).data("location-target");
    }
  });

  //footer
  $("#footer-button").click(function () {
    $("#flash-message-container").toggleClass("active-error");
  });

  //custom dialog from jquery for editing a head
  function splitHeadDialog() {
    var formForField = $(this).closest('form');
    if (dialogEvent == 'click') {
      return;
    }
    if ($("." + formForField.data('headId') + "_head_area").length > 1 && this.name.includes("handler_name_101") == false) {
      $("#dialog-confirm").dialog({
        resizable: false,
        height: 240,
        width: 600,
        modal: true,
        buttons: {
          "Create New": function () {
            formForField.parents(".entry-line").find(".head-selector").val(null);
            var form = formForField.parents(".entry-line").find(".registration_form")[0];
            Rails.fire(form, submit);
            $(this).dialog("close");
          },
          "Change Current ": function () {
            Rails.fire(formForField[0], 'submit');
            $(this).dialog("close");
          }
        }
      });
    }else{
      Rails.fire(formForField[0], 'submit');
    }
  }

  //field specific click handler for editing a head
  $('.head_animal_ear_tag_number, .head_animal_gender, .head_animal_ear_tattoo, .head_animal_name').click(function() {
    var formForField = $(this).closest('form');

    dialogEvent = 'click';

    $('#dialog-confirm-create-new').dialog({
      resizable: false,
      height: 240,
      width: 600,
      modal: true,
      buttons: {
        "Create New": function () {
          formForField.parents(".entry-line").find(".head-selector").val(null);
          var form = formForField.parents(".entry-line").find(".registration_form")[0];
          Rails.fire(form, 'submit');
          dialogEvent = '';
          $(this).dialog("close");
        },
        "Cancel": function () {
          dialogEvent = '';
          $(this).dialog("close");
        }
      }
    });

    $(".ui-dialog-titlebar-close").click(function () {
      dialogEvent = '';
    });

  });

});
