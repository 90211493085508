/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "core-js/stable"
import "regenerator-runtime/runtime"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "jquery"
import "jquery-ui"
import "bootstrap"
import "cleave.js";
import "flatpickr"
import "stripe"
import "chartkick"
import "chart.js"
import "selectize"
import "datatables.net-bs4"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "flatpickr/dist/flatpickr.css"
import "jquery-ui/ui/widget"
import "jquery-ui/ui/widgets/sortable"
import "jquery-ui/ui/disable-selection"
import "jquery-ui/ui/widgets/datepicker"
import "jquery-ui/ui/widgets/tabs"
import "jquery-ui/ui/widgets/accordion"
import "jquery-ui/ui/widgets/draggable"
import "jquery-ui/ui/widgets/droppable"
import "jquery-ui/ui/widgets/menu"

import "../src/common_validation"
import "../src/registrations/new_registration"
import "../src/registrations/edit_department"
import "../src/registrations/variants_edit"
import "../src/flatpickr_format"
import "../src/cart/slides"
import "../src/orders/orders"
import "../src/text_messages/dynamic_form"
import "../src/text_messages/edit_prepopulate"
import "../src/reports/checkin_reports"
import "../src/reports/judging_panel"
import "../src/reports/outside_awards"
import "../src/customers/checkin"
import "../src/ajax_field"
import "../src/checkDate"
import "../src/registrations/edit_department"

// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.jQuery = $;
window.$ = $;
window.Rails = Rails;
window.init_validation = init_validation
window.setupAjaxField = setupAjaxField
window.required_fields_complete = required_fields_complete
window.email_is_valid = email_is_valid
window.setup_flatpickr = setup_flatpickr
window.checkCheckInBox = checkCheckInBox
window.isDateValid = isDateValid
window.bootstrap = require("bootstrap");

require("trix")
require("@rails/actiontext")
require("@nathanvda/cocoon")
