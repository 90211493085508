$(document).ready(function() {
  $(".next-slide").click(function() {
    $thisSlide = $(this).closest(".checkout-slide");
    $nextSlide = $thisSlide.next(".checkout-slide");

    $thisSlide.fadeOut(400, function() {
      $nextSlide.fadeIn(400);
    });

    setTimeout(function() {
      $("html, body").animate(
        {
          scrollTop: $("#topElement").offset().top,
        },
        250
      );
    }, 400);
  });

  $(".prev-slide").click(function() {
    $thisSlide = $(this).closest(".checkout-slide");
    $prevSlide = $thisSlide.prev(".checkout-slide");

    $thisSlide.fadeOut(400, function() {
      $prevSlide.fadeIn(400);
    });

    setTimeout(function() {
      $("html, body").animate(
        {
          scrollTop: $("#topElement").offset().top,
        },
        250
      );
    }, 400);
  });
});

