//
// Validation Code Shared by Most Pages with an Input Form
//

import flatpickr from "flatpickr"

window.init_validation = function() {
  $('.masked-phone').toArray().forEach(function(field){
    new Cleave(field, {
      blocks: [0, 3, 3, 4],
      delimiters: ['(', ') ', '-'],
      numericOnly: true
    })
  });

  $('.masked-date').toArray().forEach(function(field){
    new Cleave(field, {
      date: true,
      delimiter: '/',
      datePattern: ['m', 'd', 'Y']
    });
  });

  $('.masked-ssn').toArray().forEach(function(field){
    new Cleave(field, {
      blocks: [3, 2, 4],
      delimiter: '-',
    })
  });

  $('.masked-ein').toArray().forEach(function(field){
    new Cleave(field, {
      blocks: [2, 7],
      delimiter: '-',
    })
  });

  $('.masked-number-3d-max').toArray().forEach(function(field){
    new Cleave(field, {
      blocks: [3]
    })
  });

  $('.masked-zip-code').toArray().forEach(function(field){
    new Cleave(field, {
      blocks: [6]
    })
  });

  $('.input-credit-card').toArray().forEach(function(field){
    new Cleave(field, {
      creditCard: true
    });
  });

  $('.cc-expiration').toArray().forEach(function(field){
    new Cleave(field, {
      date: true,
      datePattern: ['m', 'y']
    });
  });

  $('.datepicker').toArray().forEach(function(field){
    new Flatpickr(field, {
      dateFormat: "m/d/y"
    })
  });
}

window.init_datepickr = function (modalName){
  if(modalName != undefined) {
    var modalToTarget = document.getElementById(modalName);
  }

  // The onOpen and onClose options are to get around a flatpickr/bootstrap 4 bug when using bootstrap modals
  if(modalToTarget != undefined) {
    var optionsObjectHour = {
      enableTime: true,
      minuteIncrement: 1,
      dateFormat: "Y-m-d H:i",
      onOpen: function(selectedDates, dateStr, instance){
          modalToTarget.removeAttribute('tabindex');
      },
      onClose: function(selectedDates, dateStr, instance){
          modalToTarget.setAttribute('tabindex', -1);
      }
    }
  } else {
    var optionsObjectHour = {
      enableTime: true,
      dateFormat: "Y-m-d H:i"
    }
  }

  if(modalToTarget != undefined) {
    var optionsObject = {
      dateFormat: "m/d/y",
      minuteIncrement: 1,
      onOpen: function(selectedDates, dateStr, instance){
          modalToTarget.removeAttribute('tabindex');
      },
      onClose: function(selectedDates, dateStr, instance){
          modalToTarget.setAttribute('tabindex', -1);
      }
    }
  } else {
    var optionsObject = {
      dateFormat: "m/d/y"
    }
  }


  $('.datepickr').toArray().forEach(function(field){
    new flatpickr(field, optionsObject)
  });

  $('.datepickr-hour').toArray().forEach(function(field){
    new flatpickr(field, optionsObjectHour)
  });
}

// Checks all inputs with required class and adds error class if empty

window.required_fields_complete = function() {
  var found_error_flag = 0

  $('INPUT.required').each(function () {
    var value = $.trim($(this).val())
    if (value === '') {
      $(this).addClass('error')
      $(this).addClass('is-invalid')
      found_error_flag = 1
    } else {
      $(this).removeClass('error')
      $(this).removeClass('is-invalid')
    }
  })

  $('SELECT.required').each(function () {
    if ($(this).prop('selectedIndex') === 0) {
      $(this).addClass('error')
      $(this).addClass('is-invalid')
      found_error_flag = 1
    } else {
      $(this).removeClass('error')
      $(this).removeClass('is-invalid')
    }
  })

  $('TEXTAREA.required').each(function () {
    if ($(this).val() === '') {
      $(this).addClass('error')
      $(this).addClass('is-invalid')
      found_error_flag = 1
    } else {
      $(this).removeClass('error')
      $(this).removeClass('is-invalid')
    }
  })

  return found_error_flag !== 1
}

window.masked_date_legible = function() {
  let errorFound = false;

  $(".masked-date").each(function () {
    // Is this really how we want to do this?
    // Cleave will make sure it is 2 digit, 2 digit, 4 digit.
    // This does not clash with required check! Great!
    // The addClass / removeClass seems like it would.
    if ($(this).val() !== "" && /\d\d\/\d\d\/\d\d\d\d/.test($(this).val()) == false) {
      errorFound = true;

      $(this).addClass("error is-invalid");
    } else {
      $(this).removeClass("error is-invalid");
    }
  });

  return !errorFound;
}

// function email_is_valid (email)
window.email_is_valid = function(email) {
  var re = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i
  if (re.test(email.val()) == false && email.length > 0) {
    alert('Please enter a valid email.')
    email.val('')
    required_fields_complete()
  } else {
    return true
  }
}
