$(document).ready(function () {
    // Need to do on document to handle page redirect and not reload this call.
    $(document).on("click", ".mail-packet-checkbox", function () {
        //dont update this is a order that doesn't get a packet
        if ($(this).children('i.no-select').length > 0){
            return
        }

        var cust_id = this.parentElement.children[0].innerText;
        var updateValue = "not_sent";

        if ($(this).children('i.fa-exclamation-triangle').length > 0) {
            $(this).children('i.fa-exclamation-triangle').remove();

            //in this case we want to get the current value of the checkbox. not the
            if ($(this).children('i.fa-check-square').length > 0){
                updateValue = "sent";
            }

        } else {
            if ($(this).children('i.fa-square').length > 0) {
                $(this).children('i.fa-square').removeClass("fa-square").addClass("fa-check-square");
                //change value because were updating object
                updateValue = "sent";

            } else {
                $(this).children('i.fa-check-square').removeClass("fa-check-square").addClass('fa-square');
                //change value because were updating object
                updateValue = "not_sent";
            }
        }

        //Ajax post to update this Order status
        $.ajax({
         type: 'put',
         url: "/orders/" + (cust_id - 5100000),
         data: { mail_packet_status: updateValue},
         error: function (xhr, ajaxOptions, thrownError){
             $(this).children().last().after("<i class='far fa-exclamation-triangle'></i>");
         },
         dataType: "json"
         });

    });

    $('.ticket-link').click(function () {
        var cust_id = this.parentElement.parentElement.children[0].innerText;
        console.log(cust_id, "CUSTY")

        //Ajax post to update this Order status
        $.ajax({
         type: 'put',
         url: "/orders/" + (cust_id - 5100000),
         data: { printed: true},
         error: function (xhr, ajaxOptions, thrownError){
             $(this).children().last().after("<i class='far fa-exclamation-triangle'></i>");
         },
         dataType: "json"
         });

    });
});
