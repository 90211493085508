// The below is in this shared file because this check on an award
// being assigned already is done both in the adding of an outside
// award and in the adding of an award to a registration.
// Updating displays has the same situation, and so two functions
// for that are also in this file.
const assignmentWarning = (input, afterAction) => {
  $.ajax({
    url: `/special_awards/${input.val()}/assigned`,
    success: function (res) {
      if (!input.data('allow-multiple') && input.is(':checked') && res.id !== false) {
        var confirm_text = "This award is already assigned to " + res.formatted_id +
            ".\nThis award can only apply to one entry.\n\nAre you sure you'd like to change this award?";

        if (confirm(confirm_text) == false) {
            input.prop('checked', false);
            return;
        }
      }

      // afterAction is only called if the action is confirmed
      // (see early `return` above)
      afterAction(res.id);
    }
  });
};

const updateDisplayedAwardsForRegistrationId = registration_id => {
  var newManageDiv = '';

  $('.judging_select_input[data-registration-id=' + registration_id + ']:checked').each(function () {
      var label = $("label[for='" + $(this).attr('id') + "']").text();
      newManageDiv += '<div class="award-manage-row">' + label + '</div>';
  });

  $('#judging_manage_div_' + registration_id).html(
    '<div class="text-end mt-2 pe-4">' + (newManageDiv === '' ? '<a href="javascript:;">Manage Awards</a>' : newManageDiv) + '</div>'
  );
};

function reloadExhibitorAwards() {
    $.ajax({
      method: "GET",
      url: "/reports/exhibitor_awards",
      data: {
        department_ids: $("#ec-exhibitor-awards-data").attr("data-department-ids").split(","),
        division_ids: $("#ec-exhibitor-awards-data").attr("data-division-ids").split(","),
        division_category_ids: $("#ec-exhibitor-awards-data").attr("data-division-category-ids").split(",")
      },
      success: function (res) {
        $(".ec-exhibitor-awards").html(res);
      }
    })
  }

export { assignmentWarning, updateDisplayedAwardsForRegistrationId, reloadExhibitorAwards };
