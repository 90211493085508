(function() {
  $(window).ready(function() {
    var department_id, division_ids;
    if ($('#division_box :selected').text() !== "") {
      $('#division_box').removeAttr('disabled');
      $('#division_box')[0].selectize.enable();
      if ($('#department_box :selected').length === 1) {
        department_id = $('#department_box').val();
        $.ajax("division_select/" + department_id, {
          type: "GET",
          dataType: "json",
          success: function(result) {
            console.log(result);
            $('#division_box').selectize()[0].selectize.destroy();
            $("#division_box").html(result.divisions);
            return $('#division_box').selectize({
              plugins: ['remove_button'],
              dropdownParent: 'body',
              closeAfterSelect: true
            });
          },
          error: function(xhr, ajaxOptions, thrownError) {
            console.log(xhr.status);
            console.log(thrownError);
            return console.log(xhr.responseText);
          }
        });
      }
    }
    if ($('#division_category_box :selected').text() !== "") {
      $('#division_category_box').removeAttr('disabled');
      if ($('#division_box :selected').length > 1) {
        division_ids = $('#division_box').val();
        $.ajax("multi_division_category_select/", {
          type: "GET",
          dataType: "json",
          data: {
            divisions_ids: division_ids
          },
          success: function(result) {
            console.log(result);
            return $("#division_category_box").html(result.division_categories_distinct);
          },
          error: function(xhr, ajaxOptions, thrownError) {
            console.log(xhr.status);
            console.log(thrownError);
            return console.log(xhr.responseText);
          }
        });
      }
    }
    if ($('#class_box :selected').text() !== "") {
      return $('#class_box').removeAttr('disabled');
    }
  });

}).call(this);