$(document).ready(function () {
  //set you are here
  var registration_you_are_here = $("#you-are-here-registration").data("registration");
  if( registration_you_are_here > 0) {
    //debugger;
    var you_are_here_div = $(("#panel_registration_"+ registration_you_are_here))
    setYouAreHere(you_are_here_div);
    location.href = you_are_here_div.selector;
  }

  //rebind after any ajax call is complete. so if we return a ajax_field inside of another partial it runs correct
  $(document).ajaxStop(function () {
    modalBinding();
  });

  //bind to our modal links, to fill modal
  modalBinding();

  function modalBinding() {
    $('.modal-bttn').unbind('ajax:success');

    $('.modal-bttn').bind('ajax:success', function (evt, data, status, xhr) {
      //$('.you-are-here').remove();
      //$(this).closest('.registration').before("<i class=' you-are-here fa fa-map-marker fa-rotate-270'></i>");
      setYouAreHere($(this).closest('.registration'));

      $('#modal').html('<div id="modal"><h3>Loading Available Classes... <i class="fa fa-spinner fa-spin"></i></h3></div>');

      $('#modal').dialog({
        autoOpen: true,
        height: $(window).height() - 80,
        width: 900,
        modal: true,
        resizable: false,
        draggable: false,
        buttons: {
          'Save': {
            click: function () {
              $(this).dialog("close");
            },
            text: 'Save',
            id: 'modal-save-btn'
          },
          Cancel: function () {
            $(this).dialog("close");
          }
        }

      });

      $('#modal').html(data);

      if ($(this).hasClass("modal-save")) {
        $('#modal-save-btn').click(function () {
            $('#modal-save-btn').parents('.ui-dialog').find('form').submit().done(function() {
            if ( $(this).parents('.ui-dialog').find('#from_registrations_show').val() == 'true' ) {
              var regID = $('#id').val();
              var url = '/registrations/' + regID + '/admin_edit_paid_registration_update_info';
              $.ajax({ url: url, type: 'get', dataType: 'script' });
            }
          });

        });
      }
      else {
        editDepartmentCheckin();
        $(".ui-dialog-buttonpane button:contains('Save')").button("disable");
      }


      ajaxModalBind('#registration_form', ('#panel_registration_' + $('#id').val()));
      ajaxModalBind('#head_form', ('#partial_head_' + $('#id').val()));
    });

  }

  function ajaxModalBind(bindTo, htmlReplace) {
    $(bindTo).unbind('ajax:beforeSend');
    $(bindTo).unbind('ajax:error');
    $(bindTo).unbind('ajax:success');

    $(bindTo).bind('ajax:beforeSend', function (evt, data, status, xhr) {
      $(htmlReplace).html('<i class="fa fa-spinner fa-pulse loading"></i>');
    });

    $(bindTo).bind('ajax:error', function () {
      $(htmlReplace).html('<i class="fa fa-exclamation-triangle error"></i>');
    });

    $(bindTo).bind('ajax:success', function (evt, data, status, xhr) {
      $(htmlReplace).replaceWith(data);
    });
  }

  function editDepartmentCheckin() {
    theQuickening($('#breadcrumb').data('showDiv'));


    // Department / Department Selection onClick Handler
    $('#department_edit_div').on("click", ".department_row", null, function (event, manual) {


      if (typeof manual === "undefined") {
        // Need to clear future clicks
        $('#registration_show_department_id').val('');
        $('#registration_show_division_id').val('');
        $('#registration_show_division_category_id').val('');
        $('#registration_show_variant_id').val('');
      }

      $('.selected_department_row').removeClass('selected_department_row');

      $.ajax({
        url: "/registrations/select_division/" + $(event.currentTarget).data('departmentId'),
        data: 'registration_type=' + $('.department-list').data('registrationType')
      }).done(function (newhtml) {
        $('#division_edit_div').html(newhtml);
        $('#category_edit_div').html('');
        $('#registration_variant_edit_div').html('');

        var registration_show_division_id = $('#registration_show_division_id').val();
        if (registration_show_division_id.length > 0) {
          var x = $('.division_row[data-division-id=' + registration_show_division_id + ']');

          if (x.length) {
            x.trigger("click", "onpageload");
          }
        } else if ($('.division_row').length == 1) {
          // If there is only one, select it for the user
          $('.division_row').trigger("click");
        }
      });

      $(event.currentTarget).addClass('selected_department_row');

      //if an admin is editing this registration after the order is paid we need to not let them edit department
      if ($('div[data-sf-registration-status]').data('sfRegistrationStatus')) {
        $('.department-list').html(" Entry has been Paid, Department locked")
        //$('.department-list').hide();
      }
    });

    // Department / Division Selection onClick Handler
    $('#division_edit_div').on("click", ".division_row", null, function (event, manual) {

      if (typeof manual === "undefined") {
        // Need to clear future clicks
        $('#registration_show_division_id').val('');
        $('#registration_show_division_category_id').val('');
        $('#registration_show_variant_id').val('');
      }


      $('.selected_division_row').removeClass('selected_division_row');

      $.ajax({
        url: "/registrations/select_category/" + $(event.currentTarget).data('divisionId')
      }).done(function (newhtml) {
        $('#category_edit_div').html(newhtml);
        $('#registration_variant_edit_div').html('');

        var registration_show_division_category_id = $('#registration_show_division_category_id').val();
        if (registration_show_division_category_id.length > 0) {
          var x = $('.category_row[data-category-id=' + registration_show_division_category_id + ']');

          if (x.length) {
            x.trigger("click", "onpageload");
          }
        } else if ($('.category_row').length == 1) {
          // If there is only one, select it for the user
          $('.category_row').trigger("click");
        }
      });

      $(event.currentTarget).addClass('selected_division_row');


    });

    // Department / Category Selection onClick Handler
    $('#category_edit_div').on("click", ".category_row", null, function (event, manual) {

      if (typeof manual === "undefined") {
        // Need to clear future clicks
        $('#registration_show_division_category_id').val('');
        $('#registration_show_variant_id').val('');
      }


      $('.selected_category_row').removeClass('selected_category_row');


      $.ajax({
        url: "/registrations/select_show_variant/" + $(event.currentTarget).data('categoryId')
      }).done(function (newhtml) {
        $('#registration_variant_edit_div').html(newhtml);

        var registration_show_variant_id = $('#registration_show_variant_id').val();
        if (registration_show_variant_id.length > 0) {
            var x = $('.show_variant_row[data-sf-class-id=' + registration_show_variant_id + ']');
          if (x.length) {
            x.trigger("click", "onpageload");
          }
          } else if ($('.show_variant_row').length == 1) {
          // If there is only one, select it for the user
          $('.show_variant_row').trigger("click");
        }

      });

      $('#registration_show_division_category_id').val($(event.currentTarget).data('categoryId'));

      if (typeof manual === "undefined") {
        $('#registration_show_variant_id').val('');
      }

      $(event.currentTarget).addClass('selected_category_row');


    });

    // Department / Class Selection onClick Handler
    $('#registration_variant_edit_div').on("click", ".show_variant_row", null, function (event, manual) {


      if (typeof manual === "undefined") {
        // Need to clear future clicks
        $('#registration_show_variant_id').val('');
      }


      $('.selected_show_variant_row').removeClass('selected_show_variant_row');

      $('#registration_show_variant_id').val($(event.currentTarget).data('sfClassId'));

      $(event.currentTarget).addClass('selected_show_variant_row');

      var rowi = $(event.currentTarget).data('sfIndianaClass') ? 'true' : 'false';
      var regi = $('#indiana_classes_allowed').val();


    });

    // Populate Department if one previously selected
    if ($('#registration_show_department_id').length && $('#registration_show_department_id').val().length > 0) {
      var x = $('.department_row[data-department-id=' + $('#registration_show_department_id').val() + ']');

      if (x.length) {
        x.trigger("click", "onpageload");
      }
    }

    // Hide division/category/show so there can be only one(visible)
    function theQuickening(showDiv) {

      $('#breadcrumb').children().not('.past').addClass('past');
      $('#division_edit_div').hide();
      $('#category_edit_div').hide();
      $('#registration_variant_edit_div').hide();

      switch (showDiv) {
        case 'division':
          $('#division_edit_div').show();
          $('#bread_division').removeClass('past');
          break;
        case 'category':
          $('#category_edit_div').show();
          $('#bread_category').removeClass('past');
          break;
        case 'show':
          $('#registration_variant_edit_div').show();
          $('#bread_show').removeClass('past');
          break;
        default:
          break;
      }
    }

    $('.sf-submit-button').click(function () {

      if ($('#division_edit_div:visible').length > 0) {
        theQuickening('category');
        return;
      }

      if ($('#category_edit_div:visible').length > 0) {
        theQuickening('show');
        return;
      }

      if ($('#registration_variant_edit_div:visible').length > 0) {
        if ($('#registration_show_variant_id').val() != '') {
          Rails.fire($('#registration_form')[0], 'submit');
          $('#modal').dialog("close");
        }
      }

    });

    $('#bread_division').click(function () {
      theQuickening('division');
    });

    $('#bread_category').click(function () {
      theQuickening('category');
    });

    $('#bread_show').click(function () {
          if ($('#registration_show_division_category_id').val() == ''){
            theQuickening('category');
          }
          else {
            theQuickening('show');
          }
        }
    );
  }
});

// check to see if a 4h animal can be checked in
window.checkCheckInBox = function(headId,registrationId,checkedIn) {
  // Check to see if the registration is already checked in. If it is, the user
  //can do whatever they want.
  if (checkedIn){
    return true;
  }
  // If the registration is not checked in, check the 4h box that is associated
  // with it to see if it is YES or NO.
  else {
    $a = check4h(headId);

    if ($a === "NO"){
      alert ("Please override the '4H' box to 'YES' before checking in.");
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    else {
      return true;
    }
  }
}
// Check to see what the value of the 4H box is.
function check4h(headId) {
  head_box = $('#partial_head_' + headId);
  return head_box.find('.head_field_4h_input').val();
}

window.setYouAreHere = function(registrationDiv){
    $('.you-are-here').remove();
    registrationDiv.before("<i class='you-are-here fas fa-map-marker fa-rotate-270'></i>");
}