// This file is used by checkin_reports/judgin_entry.html.erb

import { assignmentWarning, updateDisplayedAwardsForRegistrationId, reloadExhibitorAwards } from './awarding';

$(document).ready(function () {
    $('.judging_text_input').change(function () {

        var newValue = $(this).val().toUpperCase();

        $(this).val(newValue);

        var data2post = {
            placing_type: $(this).data('placingType'),
            judging_placing: newValue
        };

        $.post($(this).data('ajaxUrl'), data2post)
            .fail(function () {
                alert('Could not update entry.  Refresh the page.');
            });
    });

    $('.judging_scoring_input').on('change', function() {
      $.post($(this).data('ajaxUrl'), {
        score: $(this).val()
      }).fail(function() {
        alert("Couldn't update the score. Please refresh and try again")
      })
    })

    $('.judging_criterium_input').on('change', function() {
        const dataToSend = {}
        const paramKey = $(this).data('criterium')
        const paramValue = $(this).val()
        dataToSend[paramKey] = paramValue
        $.post($(this).data('ajaxUrl'), dataToSend, {
        }).fail(function() {
          alert("Couldn't update the judging criteria. Please refresh and try again")
        })
      })

    $(document).on('change', '.judging_select_input', function () {
        const input = $(this);

        assignmentWarning(input, () => {
            if (!input.data('allow-multiple')) {
                // Unset all the others... Server does DB too
                $("input[value='" + input.val() + "']").each(function () {
                    if (input.is(this)) {
                        return;
                    }

                    if ($(this).prop('checked')) {
                        $(this).prop('checked', false);
                        updateDisplayedAwardsForRegistrationId($(this).data('registrationId'));
                    }
                });
            }

            // Add / Remove entry on main page
            updateDisplayedAwardsForRegistrationId(input.data('registrationId'));

            var data2post = {
                registration_ids: [input.data('registrationId')],
                checked: input.is(':checked')
            };

            $.ajax({
                method: 'PATCH',
                url: input.data('awardUrl'),
                data: data2post,
                success: function () {
                    reloadExhibitorAwards();
                }
            })
                .fail(function () {
                    alert('Could not update entry.  Refresh the page.');
                });
        });
    });

    $('.judging-manage-awards').click(function () {
        var registration_id = $(this).data('registrationId');
        $('.add-award-dialog[id=' + registration_id + ']').modal('show');
    });
});
